<template>
  <div
    :class="[
      'w-full h-full group/status',
      { '!cursor-not-allowed': disabled },
    ]"
  >
    <StatusSelectPopover
      ref="statusRef"
      :selected-status-id="task.status?.id"
      :parent-id="task.boardId"
      :disabled="disabled"
      source="board"
      @change="handleUpdateTask($event)"
    >
      <div
        :class="[
          'w-full h-full px-2 py-1.5 max-h-8',
          { '!cursor-not-allowed': disabled },
        ]"
        @click="emit('click')"
      >
        <div v-if="task.status" class="flex items-center justify-between gap-2">
          <StatusBadge
            v-if="task.status"
            class="flex items-center gap-1 text-xs py-0.5 min-h-5 px-1.5 w-fit h-full max-h-5"
            :type="task.status.type"
            :label="task.status.name"
            :disabled="disabled"
          />
          <Icon name="i-heroicons-chevron-down-20-solid" class="size-5 text-gray-400 hidden group-hover/status:block" />
        </div>
        <UButton
          v-else
          variant="ghost"
          color="gray"
          class="font-normal text-gray-400"
          size="2xs"
          :disabled="disabled"
        >
          -
        </UButton>
      </div>
    </StatusSelectPopover>
  </div>
</template>

<script lang="ts" setup>
import type { TaskItem } from '#task/types'
import type { Status } from '#status/types'

const props = defineProps({
  task: {
    type: Object as PropType<TaskItem>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['set-callback', 'click'])
const { updateTask, updateTasks } = useUpdateTask()
const { checkedTasks } = useListViewSharedState()
const statusRef = ref()

const handleUpdateTask = (status: string) => {
  if (checkedTasks.value?.has(props.task.id)) {
    updateTasks(Array.from(checkedTasks.value), { status })
    return
  }

  updateTask(props.task.id, { status })
}

onMounted(() => {
  emit('set-callback', {
    open: () => statusRef.value?.open(),
  })
})
</script>
